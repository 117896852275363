<template>
  <div class="">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{$t('i18nn_55793d80c64c2dc7')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('i18nn_4c14f792298e4e94')}}</el-breadcrumb-item>
    </el-breadcrumb> -->

    <div class="tableConTop">
      <el-row>
        <el-col :span="6" class="tableConTopLeft">
          <el-breadcrumb separator-class="el-icon-arrow-right"><el-breadcrumb-item>{{$t('i18nn_4c14f792298e4e94')}}</el-breadcrumb-item></el-breadcrumb>
        </el-col>
        <el-col :span="18" class="tableConTopRig">
					
          <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null)">{{$t('c0246c55b9cac963')}}</el-button>
					<el-button type="warning" icon="el-icon-refresh" size="small" @click="DicRefresh()">{{$t('i18nn_2f8d01a5e86ae412')}}</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- <el-menu :default-active="$route.path" :router="true" class="" mode="horizontal" @select="handleSelect">
      <el-menu-item :route="{name:'DicConfig'}" index="/Frame/DicConfig">{{$t('i18nn_cbf03affa6d6ca3d')}}</el-menu-item>
      <el-menu-item :route="{name:'ParmConfig'}" index="/Frame/ParmConfig">{{$t('i18nn_2bbc6b12fbdf9019')}}</el-menu-item>
      <el-menu-item :route="{name:'ControlConfig'}" index="/Frame/ControlConfig">{{$t('i18nn_2fb94604c67cb05c')}}</el-menu-item>
      <el-menu-item :route="{name:'MessageConfig'}" index="/Frame/MessageConfig">{{$t('i18nn_b0b291800a22292a')}}</el-menu-item>
    </el-menu> -->
    <div class="tableCon" v-loading="loading_load" :element-loading-text="$t('i18nn_b954f8829728e9d2')">
      <!--  <div class="tableConHeader">
       <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
       <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null)">{{$t('c0246c55b9cac963')}}</el-button>
     </div> -->
      <!--          <div class="tableConTop">
       <el-row>
         <el-col :span="4" class="tableConTopLeft">
 <el-breadcrumb separator-class="el-icon-arrow-right">
   <el-breadcrumb-item>&nbsp;</el-breadcrumb-item>
 </el-breadcrumb>
         </el-col>
         <el-col :span="20" class="tableConTopRig">
 <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
 <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null)">{{$t('c0246c55b9cac963')}}</el-button>
         </el-col>
       </el-row>
     </div> -->

      <div class="tableConTable">
        <div class="filterCon">
          <ul class="filterConList">
            <li>
              <span>{{$t('184333c81babf2f1')}}</span>
              <el-select filterable clearable v-model="queryParamObj.type" :placeholder="$t('i18nn_907e5f417e2967fd')" size="small" @change="initData()">
                <el-option v-for="item in selectOption.types" :key="item.code" :label="item.code" :value="item.code"></el-option>
              </el-select>
            </li>
            <li>
              <!-- <span>类型、选项描述、选项值:</span> -->
              <el-input type="text" v-model="queryParamObj.keyword" placeholder="根据类型、选项描述、选项值" size="small" clearable></el-input>
            </li>
            <li><el-button type="primary" @click="initData()" size="small">查 询</el-button></li>
          </ul>

          <!-- <el-row>
              <el-col :span="3">
               <el-select filterable clearable v-model="queryParamObj.type" :placeholder="$t('i18nn_907e5f417e2967fd')">
                  <el-option v-for="item in selectOption.types" :key="item.code" :label="item.code" :value="item.code">
                  </el-option>
              </el-select>
              </el-col>
               <el-col :span="1">
             </el-col>
              <el-col :span="3">
               <el-input type="text" v-model="queryParamObj.keyword" placeholder="根据类型、选项描述、选项值"></el-input>
              </el-col>

              <el-col :span="2">
                <el-button type="primary" @click="initData()" size="small">查 询</el-button>
              </el-col>
            </el-row> -->
        </div>

        <el-table :data="tableData" stripe border :height="$store.state.tableMaxHeight3" style="width: 100%">
          <!-- <el-table-column
            type="selection"
            width="55">
          </el-table-column> -->
          <el-table-column type="index" :index="$Utils.tableIndex(pagination)" width="50"></el-table-column>
          <el-table-column prop="id" label="ID" width="200px"></el-table-column>
          <el-table-column prop="type" :label="$t('184333c81babf2f1')"></el-table-column>
          <el-table-column prop="codeText" :label="$t('i18nn_6e7a6111b87ee282')"></el-table-column>
          <el-table-column prop="code" :label="$t('i18nn_eca4e9acff89221b')"></el-table-column>
					<el-table-column prop="codeTextEn" label="选项值(EN)"></el-table-column>
					
          <el-table-column prop="sortNo" :label="$t('i18nn_dbcffdf31e86d2d8')"></el-table-column>

          <el-table-column prop="parentType" :label="$t('i18nn_596f184984001117')"></el-table-column>
          <el-table-column prop="parentCode" :label="$t('i18nn_ca93d1765ac4dd1d')"></el-table-column>

          <el-table-column prop="remark" :label="$t('15b3627faddccb1d')"></el-table-column>

          <el-table-column prop="updateTime" :label="$t('i18nn_b5ff6d3c4d78b76b')"></el-table-column>
          <!-- <el-table-column prop="enable" :label="$t('6cdece641436d7ab')" :formatter="formatterEnable">
            <template slot-scope="scope">
              <el-tag v-if="'1'===scope.row.enable">{{$t('i18nn_889b3ef0590d9f54')}}</el-tag>
              <el-tag v-else-if="'0'===scope.row.enable" type="info">{{$t('i18nn_11419332713360d2')}}</el-tag>
              <el-tag v-else type="info">{{scope.row.enable}}</el-tag>
            </template>
          </el-table-column> -->
          <el-table-column :label="$t('93f5ca01b006206c')" width="200px">
            <template slot-scope="scope">
              <el-button @click="openDioalog(scope.row)" type="warning" size="mini" icon="el-icon-edit">{{$t('c09a4ec2fe473b0a')}}</el-button>
              <el-button @click="delPageAction(scope.row)" type="danger" size="mini" icon="el-icon-minus">{{$t('e33c9b93c36fd250')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tableConPagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
    </div>
    <!-- 修改弹窗 -->
    <el-dialog :title="$t('6267f3aedf895209')" :close-on-click-modal="false" :visible.sync="dialogFormVisible">
      <el-form ref="form" :rules="formRules" :model="form" label-width="120px" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
        <el-form-item label="类型：" prop="type"><el-input type="text" v-model="form.type" :placeholder="$t('5a9aefbc03c778f7')"></el-input></el-form-item>
        <el-form-item label="选项描述：" prop="codeText"><el-input type="text" v-model="form.codeText" :placeholder="$t('5a9aefbc03c778f7')"></el-input></el-form-item>
        <el-form-item label="选项值：" prop="code"><el-input type="text" v-model="form.code" :placeholder="$t('5a9aefbc03c778f7')"></el-input></el-form-item>
				<el-form-item label="选项值(EN)：" prop="codeTextEn"><el-input type="text" v-model="form.codeTextEn" :placeholder="$t('5a9aefbc03c778f7')"></el-input></el-form-item>
				
        <el-form-item label="排序：" prop="sortNo"><el-input type="text" v-model="form.sortNo" :placeholder="$t('5a9aefbc03c778f7')"></el-input></el-form-item>
        <el-form-item label="父类型：" prop=""><el-input type="text" v-model="form.parentType" :placeholder="$t('5a9aefbc03c778f7')"></el-input></el-form-item>
        <el-form-item label="父选项值：" prop=""><el-input type="text" v-model="form.parentCode" :placeholder="$t('5a9aefbc03c778f7')"></el-input></el-form-item>

        <el-form-item label="备注描述：" :placeholder="$t('i18nn_4eed7667ef911a72')"><el-input type="textarea" v-model="form.remark" :maxlength="1000" show-word-limit></el-input></el-form-item>
        <!-- <el-form-item>
          <el-checkbox v-model="form.isDefault">{{$t('i18nn_89beeeac1bb28e32')}}</el-checkbox>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogFormVisible = false">{{$t('4e9fc68608c60999')}}</el-button>
        <el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{$t('4ce9979bfb6576d9')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  // name: 'HomeConfFrame',
  // //meta信息seo用
  // metaInfo: {
  //   title: '互易天下-管理后台-系统管理'
  // },
  data() {
    return {
      dialogFormVisible: false,
      dialogFormStatus: 0, //0-新增，1-修改
      queryParamObj: {
        type: '',
        keyword: ''
      },
      selectOption: {
        types: []
      },
      // selectOption: {
      //   deptList:[],
      // },
      //表格数据
      //loading,表格数据
      loading: false,
      //表格数据
      tableData: [],
      //分页数据
      pagination: this.$Utils.defaultPagination(),
      //表格选择的数据
      // multipleSelection:[],
      //查询，排序方式
      filterData: {
        orderBy: 'create_time', //排序字段
        sortAsc: 'desc' //desc降序，asc升序
      },
      //弹窗
      dialogTableVisible: false,
      loading_load: false,
      form: {
        id: null,
        type: '', //类型
        codeText: '', //选项描述
        code: '', //选项值
				codeTextEn:'',
        sortNo: '', //排序
        parentType: '', //父类型
        parentCode: '', //父选项值
        remark: '' //备注描述
      },
      formRules: {
        type: [
          { required: true, message: this.$t('i18nn_2142b1d4d10d8b5c'), trigger: 'blur' }
          // { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        codeText: [
          { required: true, message: this.$t('i18nn_a412adde8aa61969'), trigger: 'blur' }
          // { type: 'number', message: '手机号必须为数字'}
        ],
				codeTextEn: [
          { required: true, message: '请输入选项EN', trigger: 'blur' }
          // { type: 'number', message: '手机号必须为数字'}
        ],
        code: [{ required: true, message: this.$t('i18nn_9a85d5fea7339b0b'), trigger: 'blur' }],
        sortNo: [{ required: true, message: this.$t('i18nn_1c3b377187cb4bb2'), trigger: 'blur' }],
        parentType: [{ required: true, message: this.$t('i18nn_2a8b31eaadca2ae7'), trigger: 'blur' }],
        parentCode: [{ required: true, message: this.$t('i18nn_632499709fae44e4'), trigger: 'blur' }],
        remark: [{ required: true, message: this.$t('i18nn_4eed7667ef911a72'), trigger: 'blur' }]
      }
    };
  },
  //创建时
  created() {
    this.initData();
    this.getDicData();
		//数据字典
		// getDicData(['wh_ec_order_status'],
		// 	(data)=>{
		// 		this.selectOption.wh_ec_order_status = data.data['wh_ec_order_status'];
		// });
  },
  //编译挂载前
  mounted() {
    //初始化省市县
    // this.initLinkageData = {
    //   provinceId: '',
    //   cityId: '',
    //   countyId: '',
    // }
    // console.log("mounted");
  },
  methods: {
    initData() {
      this.pagination.current_page = 1;
      this.getPageData();

      //部门数据
      // this.getProvinceData();
      // this.getDetData("");
    },
    //状态
    // formatterEnable(row, column){
    //   if('1'===row.enable){
    //     return this.$t('i18nn_889b3ef0590d9f54');
    //   } else if('0'===row.enable){
    //     return this.$t('4e9fc68608c60999');
    //   } else {
    //     return row.enable;
    //   }
    // },
		
		DicRefresh(){
			this.loading_load = true;
			
			this.$http
			  .get(this.$urlConfig.HyDicRefresh, {})
			  .then(({ data }) => {
			    console.log(this.$t('i18nn_bc868e024b80d2e3'));
			    console.log(data);
			    
			    this.loading_load = false;
			    if (200 == data.code) {
						this.$message.success(this.$t('i18nn_9d14fa135072faa4'));
			      // this.dialogFormVisible = false;
			      // this.getPageData();
			      // this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
			      //   type: 'success',
			      //   confirmButtonText: this.$t('204ffab8a6e01870')
			      // });
			    } else {
			      if (!data.msg) {
			        data.msg = '提交失败,请重试';
			      }
			      this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
			        type: 'warning',
			        // confirmButtonText: this.$t('204ffab8a6e01870')
			      });
			    }
			  })
			  .catch(error => {
			    console.log(error);
			    console.log(this.$t('i18nn_a7d2e953195a5588'));
			    this.loading_load = false;
			    this.$alert('提交失败,请重试！', this.$t('cc62f4bf31d661e3'), {
			      type: 'warning',
			      // confirmButtonText: this.$t('204ffab8a6e01870')
			    });
			  });
		},
    //选择
    // handleSelectionChange(val) {
    //     this.multipleSelection = val;
    //   },
    //打开新增编辑，弹窗
    openDioalog(formParm) {
      console.log(formParm);
      this.dialogFormVisible = true;
      let form = Object.assign({}, formParm);
      console.log(form);
      if (null === formParm) {
        //新增
        // 重置
        this.resetForm('form');
        this.dialogFormStatus = 0;
        //初始化省市县
        // this.initLinkageData = {
        //   provinceId: '',
        //   cityId: '',
        //   countyId: '',
        // }
        // this.clear(form);
      } else {
        //修改
        // 重置
        this.resetForm('form');

        this.dialogFormStatus = 1;
        // form.enable = form.enable === '1' ? true : false;

        //浅拷贝、对象属性的合并
        // this.form = Object.assign({}, form);
        this.getUserDetData(form.id);
        //初始化省市县
        // this.initLinkageData = {
        //   provinceId: form.provinceCode,
        //   cityId: form.cityCode,
        //   countyId: form.countyCode,
        // }
      }
    },
    //提交信息
    submitForm(formName) {
      //验证省市县必输入

      // if (!this.form.provinceCode || !this.form.cityCode || !this.form.countyCode) {
      //   this.$alert('地址省市县数据必填！', this.$t('cc62f4bf31d661e3'), {
      //     type: 'warning',
      //     confirmButtonText: this.$t('204ffab8a6e01870'),
      //   });
      //   return;
      // }
      this.$refs[formName].validate(valid => {
        // if (true) {
        if (valid) {
          // alert('submit!');
          let formData = Object.assign({}, this.form);
          //浅拷贝、对象属性的合并
          // this.form = Object.assign({},form);
          if (0 === this.dialogFormStatus) {
            // this.formData.id = null;
            formData.id = null;
            // formData.memberId = this.$store.getters.getUserInfo.buyerId;
            // formData.createBy = this.$store.getters.getUserInfo.id;
            this.postData(this.$urlConfig.HyDicAdd, formData);
          } else {
            // formData.id = null;
            // formData.memberId = this.$store.getters.getUserInfo.buyerId;
            // formData.createBy = this.$store.getters.getUserInfo.id;
            this.postData(this.$urlConfig.HyDicModify, formData);
          }
        } else {
          console.log('error submit!!');
          this.$alert('提交有误,请检查提交数据！', this.$t('cc62f4bf31d661e3'), {
            type: 'warning',
            confirmButtonText: this.$t('204ffab8a6e01870')
          });
          return false;
        }
      });
    },
    //重置输入框
    resetForm(formName) {
      console.log(formName);
      this[formName] = {};
      // console.log(this.$refs[formName]);
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
        // this.form.remark = ""; //  备注//非必填
        // this.form.parentType = ""; //  备注//非必填
        // this.form.parentCode = ""; //  备注//非必填
      } else {
        console.log('this.$refs[formName]', this.$refs[formName]);
      }
    },
    //提交信息
    postData(url, formData) {
      let _this = this;
      this.loading = true;

      this.$http
        .post(url, formData)
        .then(({ data }) => {
          console.log(this.$t('i18nn_bc868e024b80d2e3'));
          console.log(data);
          
          this.loading = false;
          if (200 == data.code) {
            this.dialogFormVisible = false;
            this.getPageData();
            this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
              type: 'success',
              confirmButtonText: this.$t('204ffab8a6e01870')
            });
          } else {
            if (!data.msg) {
              data.msg = '提交失败,请重试';
            }
            this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
              type: 'warning',
              confirmButtonText: this.$t('204ffab8a6e01870')
            });
          }
        })
        .catch(error => {
          console.log(error);
          console.log(this.$t('i18nn_a7d2e953195a5588'));
          this.loading = false;
          this.$alert('提交失败,请重试！', this.$t('cc62f4bf31d661e3'), {
            type: 'warning',
            confirmButtonText: this.$t('204ffab8a6e01870')
          });
        });
    },

    //请求分页数据
    getPageData() {
      let _this = this;
      this.loading_load = true;
      this.$http
        .put(this.$urlConfig.HyDicPage, {
          // sortAsc: this.filterData.sortAsc,
          // orderBy: this.filterData.orderBy,
          offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
          limit: this.pagination.page_size, //当前页显示数目
          type: this.queryParamObj.type,
          keyword: this.queryParamObj.keyword
        })
        .then(({ data }) => {
          console.log('字典列表，请求成功');
          console.log(data);
          
          this.loading_load = false;
          if (200 == data.code) {
            //表格显示数据
            this.tableData = data.rows;
            //当前数据总条数
            this.pagination.total = parseInt(data.total);
            //当前页数
            // this.pagination.current_page = parseInt(data.current);
            //当前页条数
            // this.pagination.page_size = parseInt(data.size);
            // this.$message.success('字典列表，请求成功');
          } else {
            this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
          }
        })
        .catch(error => {
          console.log(error);
          console.log('字典列表，请求失败');
          this.$message.error('列表数据，请求失败！');
          this.loading_load = false;
        });
    },
    //查询详情接口
    getUserDetData(id) {
      // let _this = this;
      this.loading = true;
      this.$http
        .put(this.$urlConfig.HyDicDet, {
          id: id
        })
        .then(({ data }) => {
          console.log('字典详情，请求成功');
          console.log(data);
          
          this.loading = false;
          if (200 == data.code) {
            let form = data.data;
            // form.enable = form.enable === '1' ? true : false;
            //浅拷贝、对象属性的合并
            this.form = Object.assign({}, form);
            //表格显示数据
            // this.tableData = data.rows;
            //当前数据总条数
            // this.pagination.total = parseInt(data.total);
            //当前页数
            // this.pagination.current_page = parseInt(data.current);
            //当前页条数
            // this.pagination.page_size = parseInt(data.size);
            // this.$message.success('字典详情，请求成功');
          } else {
            this.$message.warning(data.msg ? data.msg : this.$t('i18nn_35da7aebbfd476d0'));
          }
        })
        .catch(error => {
          console.log(error);
          console.log('字典详情，请求失败');
          this.$message.error('详情数据，请求失败！');
          this.loading = false;
        });
    },

    //删除操作
    delPageAction(dataParm) {
      this.$confirm(this.$t('i18nn_18a47a2171c01d1c') + dataParm.codeText + '吗?', this.$t('daaaeb1b7b22b126'), {
        confirmButtonText: this.$t('204ffab8a6e01870'),
        cancelButtonText: this.$t('4b3340f7872b411f'),
        type: 'warning'
      })
        .then(() => {
          this.delPageData(dataParm);
        })
        .catch(() => {});
    },
    getDicData() {
      this.$http
        .get(this.$urlConfig.HyDicType)
        .then(({ data }) => {
          console.log(data);
          // debugger;
          if (200 == data.code) {
            this.selectOption.types = data.rows;
          } else {
            console.log('下拉列表数据请求失败!');
          }
          this.loading_load = false;
        })
        .catch(error => {
          console.log(error);
          console.log('更新特定数据，请求失败');
          this.loading_load = false;
        });
    },
    //删除特定数据
    delPageData(dataParm) {
      let _this = this;
      console.log(dataParm);
      this.loading_load = true;
      this.$http
        .delete(this.$urlConfig.HyDicDel, { data: { id: dataParm.id } })
        .then(({ data }) => {
          console.log('删除，请求成功');
          console.log(data);
          this.loading_load = false;
          if (200 == data.code) {
            this.$alert('恭喜,删除成功！', this.$t('cc62f4bf31d661e3'), {
              type: 'success',
              confirmButtonText: this.$t('204ffab8a6e01870')
            });
            this.getPageData();
          } else {
            if (!data.msg) {
              data.msg = '删除失败,请重试';
            }
            this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
              type: 'warning',
              confirmButtonText: this.$t('204ffab8a6e01870')
            });
          }
        })
        .catch(error => {
          console.log(error);
          console.log('删除，请求失败');
          this.loading_load = false;
          this.$alert('删除失败,请重试！', this.$t('cc62f4bf31d661e3'), {
            type: 'warning',
            confirmButtonText: this.$t('204ffab8a6e01870')
          });
        });
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }*/
</style>
